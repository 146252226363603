<template>
    <div>
        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <h3 class="card-header-title" id="exampleModalCenterTitle">
                            PILIH FORMULIR
                        </h3>
                        <button type="button" class="close" @click.prevent="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="card-header">
                        <form>
                            <div class="input-group input-group-flush input-group-merge">
                                <input type="search" class="form-control form-control-prepended search" v-model="keywords_modals" v-on:keyup.enter="getDataFormulir()" placeholder="Cari formulir...">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <span class="fe fe-search"></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <ul class="list-group list-group-flush list">
                        <li class="list-group-item p-4" v-for="(formulirAll, index) in dataFormulirAdd" :key="index">
                            <div class="d-block">
                                <div class="row align-items-center row-col row-col-invert">
                                    <div class="col-auto">
                                        <div class="">
                                            <img src="https://scolacdn.com/frontend/argonne-img/class/digital-learning.png" width="50" alt="..." class="">
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="d-flex align-items-center flex-wrap">
                                            <span class="badge badge-light">Biaya Formulir : {{ formulirAll.biaya_formulir }}</span>
                                        </div>
                                        <h2 class="mb-1 mt-2">
                                            {{ formulirAll.nama_formulir }}
                                        </h2>
                                        <div class="d-flex align-items-center">
                                            <span class="badge badge-info m-0 mr-2">Kode : {{ formulirAll.kode_formulir }}</span>
                                            <span class="badge badge-success m-0 mr-2">Unit : {{ formulirAll.title }}</span>
                                            <span v-if="formulirAll.subunit" class="badge badge-warning m-0 mr-2">Sub Unit : {{ formulirAll.subunit }}</span>
                                            <span class="badge badge-light m-0 mr-2">keterangan : {{ formulirAll.keterangan }}</span>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <div class="btn-group">
                                            <button @click.prevent="SelectItems(formulirAll.id)" class="btn btn-sm btn-white">
                                                <i class="fe fe-plus mr-2"></i>Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="card-footer border-top-0">
                        <!-- <ul class="pagination mb-0"></ul> -->
                        <div class="d-flex justify-content-between">
                            <div>
                                <v-pagination v-if="pagination_modals.totalPages > 0" v-model="pagination_modals.currentPage" :page-count="pagination_modals.totalPages" :classes="pagination_modals.bootstrapPaginationClasses" :labels="pagination_modals.paginationAnchorTexts" v-on:change="getDataFormulir()"></v-pagination>
                            </div>
                            <button type="button" class="btn btn-text text-muted" @click.prevent="hide_modal()">Batal & Tutup</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="col-12 col-lg-12 col-xl-12">
            <div class="card card-top-head card-action mb-3">
                <div>
                    <a href="javascript:history.back()" class="btn btn-white"><i class="fe fe-arrow-left mr-2"></i>Kembali</a>
                </div>
            </div>
            <div class="card shadow-sm">
                <div class="card-body">
                    <div class="header mb-0">
                        <div class="header-body border-bottom-0 pb-0 pt-0">
                            <div class="row align-items-center">
                                <div class="col-auto">
                                    <div class="">
                                        <img src="https://scolacdn.com/frontend/argonne-img/class/digital-learning.png" width="80" alt="..." class="">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="d-flex align-items-center">
                                        <span class="badge badge-info mr-3 mb-3">Kode gelombang: {{ this.detailGel.kode_gelombang }}</span>
                                        <span class="badge badge-success mr-3 mb-3">Periode: {{ this.detailGel.nama_periode }}</span>
                                    </div>
                                    <h1 class="header-title mb-1">
                                        {{ this.detailGel.nama_gelombang }}
                                    </h1>
                                    <div class="d-flex align-items-center">
                                        <span class="badge badge-light mr-3 mb-3 mt-3">Mulai: {{ this.detailGel.start_date }}</span>
                                        <span class="badge badge-light mr-3 mb-3 mt-3">Selesai: {{ this.detailGel.end_date }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card shadow-sm">
                <div class="card-header pb-3">
                    <!-- Search -->
                    <form>
                        <div class="input-group input-group-flush">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fe fe-search"></i>
                                </span>
                            </div>
                            <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari formulir...">
                        </div>
                    </form>
                    <!-- Dropdown -->
                    <div class="dropdown">
                        <button class="btn btn-sm btn-primary" type="button" @click="show_modal()">
                            <i class="fe fe-plus mr-2"></i> Add Formulir
                        </button>
                    </div>

                </div>
                <div class="table-responsive">
                    <table class="table table-sm table-nowrap card-table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th class="w-100">Nama Formulir</th>
                                <th>Biaya Formulir</th>
                                <th>Quota</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="fetching">
                            <tr>
                                <td colspan="6">
                                    <div class="alert alert-primary" role="alert">
                                        Sedang mengambil data..
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!fetching">
                            <tr v-for="(formulir, index) in dataFormulirPerGelombang" :key="index">
                                <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                <td>
                                    <div class="d-block">
                                        <h2 class="mb-3 name">
                                            <a>{{ formulir.nama_formulir }}</a>
                                        </h2>
                                        <div class="d-flex align-items-center flex-wrap">
                                            <span class="badge badge-info mr-3">Kode : {{ formulir.kode_formulir }}</span>
                                            <span class="badge badge-success mr-3">Unit : {{ formulir.title }}</span>
                                            <span v-if="formulir.subunit" class=" badge badge-warning m-0 mr-2">Sub Unit : {{ formulir.subunit }} </span>
                                            <span class="badge badge-light mr-3">Ket : {{ formulir.keterangan }}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>Rp. {{ formatNumber(formulir.biaya_formulir) }}</td>
                                <td>{{ formulir.kuota_pendaftar }} Orang</td>
                                <td class="text-center">
                                    <router-link :to="{ name: 'master.formulir.setup', params: { id: formulir.id } }" class="btn btn-info btn-sm mr-2"><i class="fe fe-eye mr-2"></i>Setup & Lihat</router-link>
                                    <button @click.prevent="postDelete(formulir.id)" class="btn btn-danger btn-sm"><i class="fe fe-trash-2 mr-2"></i>Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer border-top-0">
                    <div style="float:right">
                        <!-- <ul class="pagination mb-0"></ul> -->
                        <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                kodeGelombang: this.$route.params.id,
                dataFormulirPerGelombang: [],
                dataFormulirAdd: [],
                fetching: true,
                detailGel: {
                    kode_gelombang: '',
                    kode_periode: '',
                    nama_periode: '',
                    nama_gelombang: '',
                    start_date: '',
                    end_date: '',
                    status: '',
                },
                pagination: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 5,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                pagination_modals: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 5,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                keywords_modals: ''
            }
        },
        created() {
            this.getData();
            this.getDataFormulir();
        },
        components: {
            vPagination
        },
        methods: {
            show_modal() {
                this.getDataFormulir();
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            formatNumber(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            // Get Data
            getData() {
                this.fetching = true;
                // list formulir per gelomabang by ID
                this.$http.get(this.baseUrl + `admin/gelombang/list/formulir`, {
                        params: {
                            id: this.$route.params.id,
                            page: this.pagination.currentPage,
                            keywords: this.keywords,
                        }
                    })
                    .then((response) => {
                        this.dataFormulirPerGelombang = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });

                // Detail Gelombang BY KODE GELOMABANG
                this.$http.get(this.baseUrl + `admin/master/gelombang/detail?id=${this.$route.params.id}`)
                    .then((response) => {
                        this.detailGel.kode_gelombang = response.data.data[0].kode_gelombang;
                        this.detailGel.kode_periode = response.data.data[0].kode_periode;
                        this.detailGel.nama_periode = response.data.data[0].nama_periode;
                        this.detailGel.nama_gelombang = response.data.data[0].nama_gelombang;
                        this.detailGel.start_date = response.data.data[0].start_date;
                        this.detailGel.end_date = response.data.data[0].end_date;
                    })
            },
            getDataFormulir() {
                this.dataFormulirAdd = [];
                // list formulir Yang belum di Add
                this.$http.get(this.baseUrl + `admin/gelombang/list/formulir/forAdd`, {
                        params: {
                            gelombang_id: this.$route.params.id,
                            page: this.pagination_modals.currentPage,
                            keywords: this.keywords_modals,
                        }
                    })
                    .then((response) => {
                        this.dataFormulirAdd = response.data.data;
                        this.pagination_modals.totalPages = response.data.total_page;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // select or add item
            SelectItems(id) {
                var fd = new FormData()
                fd.append('gelombang_id', this.kodeGelombang);
                fd.append('formulir_id', id);
                fd.append('setup', 'submitformulir');
                this.$http({
                    url: this.baseUrl + 'admin/gelombang/setup/formulir',
                    method: 'post',
                    data: fd
                }).then(() => {
                    this.$swal({
                        icon: 'success',
                        title: "success",
                        text: 'Add Formulir Success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$modal.hide('my-modal')
                    this.getData();
                }).catch(error => {
                    console.log(error.response.data)
                })
            },
            // Delete item
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    text: "ini akan Menghapus semua data",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/gelombang/delete/formulir',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Deleted!',
                                    'Data deleted successfully.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat di hapus!',
                                    'Formulir sedang atau sudah digunakan.',
                                    'error'
                                )
                            }
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>